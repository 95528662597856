import PropTypes from 'prop-types';
import React from 'react';
import { minimalistDate } from '../../../../utils/date';
import { I18n } from 'react-redux-i18n';
import WalletCard from '../../common/ListCard/WalletCard';
const TransactionCard = ({ transaction }) => {
    return (
        <div className='transaction-card-container'>
            <WalletCard className='transaction'>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        width: '80%',
                    }}
                >
                    <div className='transaction-status-container'>
                        <div
                            className={`arrow ${transaction.tipo === 'R' ? 'increase' : 'decline'}`}
                        ></div>
                    </div>
                    <span className='transaction-from-text'>
                        {transaction.tipo === 'R'
                            ? `${I18n.t('wallet.transactionsList.from')} ${
                                  transaction?.from_description
                              }`
                            : `${I18n.t('wallet.transactionsList.to')} ${
                                  transaction?.to_description
                              }`}
                    </span>
                </div>
                <div className='transaction-data-container'>
                    <span
                        className={`transaction-value ${
                            transaction.tipo === 'R' ? 'increase' : 'decline'
                        }`}
                    >{`${transaction.amount} NEX`}</span>
                    <span className='transaction-date'>
                        {minimalistDate(transaction.timereceived)}
                    </span>
                </div>
                <div className='state-container'>
                    <span>{transaction.state_description}</span>
                </div>
            </WalletCard>
        </div>
    );
};
TransactionCard.propTypes = {
    transaction: PropTypes.object.isRequired,
};
export default TransactionCard;
