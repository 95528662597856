import React, { useState, useEffect } from 'react';
import './NexMonitorScreen.scss';
import SearchBar from '../../../common/components/SearchBar';
import { I18n } from 'react-redux-i18n';
import Loader from '../../../common/components/Loader';
import ReactTable from 'react-table';
import TablePagination from '../../../common/components/TablePagination';
import DateRangePicker from '../../../common/components/DateRangePicker';
import Button from '../../../common/components/Button';
import { parseDate } from '../../../utils';
import SelectMultiple from '../../../common/components/SelectMultiple';
import Checkbox from '../../../common/components/Checkbox';
import { ConfirmationModal } from '../../../common/components/Modal';
import TransactionsSuccessModal from '../TransactionsSuccessModal';
import TransactionsErrorModal from '../TransactionsErrorModal';
import Swal from 'sweetalert2';

const defaultStatusSelected = [
    { id: 'all', checked: false },
    { id: 'RR', checked: true },
    { id: 'ER', checked: false },
    { id: 'AE', checked: false },
    { id: 'CR', checked: false },
    { id: 'FI', checked: true },
    { id: 'ES', checked: true },
];

const sendDataToSAPResultsInitialState = {
    allSuccessful: false,
    successfulTotal: 0,
    errorTotal: 0,
    errors: [],
};

const NexMonitorScreen = (props) => {
    const [textToSearch, setTextToSearch] = useState('');
    const [statusFilter, setStatusFilter] = useState(defaultStatusSelected);
    const [transactionsSelected, setTransactionsSelected] = useState([]);

    const [reactTable, setReactTable] = useState();

    const [sendDataToSAPResults, setSendDataToSAPResults] = useState(
        sendDataToSAPResultsInitialState,
    );

    const [showConfirmSendDataToSAPModal, setShowConfirmSendDataToSAPModal] = useState(false);

    useEffect(() => {
        const allAreSelected = allFiltersAreSelected();
        const allAreDeselected = allFiltersAreDeselected();
        const isTheAllOptionChecked = statusFilter.filter((status) => status.id === 'all')[0]
            .checked;

        // this is done to auto check the 'all' checkbox if all the other options are selected (and vise versa)
        if (
            (allAreSelected && !isTheAllOptionChecked) ||
            (allAreDeselected && isTheAllOptionChecked)
        ) {
            setStatusFilter(
                statusFilter.map((status) => {
                    if (status.id === 'all') return { ...status, checked: !isTheAllOptionChecked };
                    return status;
                }),
            );
        }

        const statusSelectedArray = statusFilter
            .filter((status) => status.id !== 'all' && status.checked)
            .map((status) => status.id);
        props.updateFilterValues({ status: statusSelectedArray });
        props.getTransactions({ statusFilter });
    }, [statusFilter]);

    useEffect(() => {
        if (props.sendDataToSAPStatus.errorMessage) {
            Swal.fire({
                icon: 'error',
                title: I18n.t('nexMonitor.sendDataToSAPErrorAlert.title'),
                text: props.sendDataToSAPStatus.errorMessage,
            }).then((result) => {
                if (result.isConfirmed) {
                    props.getTransactions({ statusFilter });
                    // todo check how to clear the ui now, this might not work
                    props.clearUI();
                }
            });
        } else {
            if (
                props.sendDataToSAPStatus.success ||
                props.sendDataToSAPStatus.error ||
                props.sendDataToSAPStatus.loading
            ) {
                const mappedResults = mapResults(props.sendDataToSAPResults);

                setSendDataToSAPResults(mappedResults);
            }
            if (!props.sendDataToSAPStatus.loading && props.sendDataToSAPStatus.success) {
                props.getTransactions({ statusFilter });
            }
        }
    }, [props.sendDataToSAPStatus]);
    /*
     * Maps the transaction payment results (quantity of success, of errors, and the errors strings).
     * */
    const mapResults = (transactions) => {
        let transactionsErrored =
            transactions.length > 0 ? transactions?.filter((t) => t.errors.length !== 0) : [];

        if (transactionsErrored.length === 0 && props.sendDataToSAPStatus.success)
            return { ...sendDataToSAPResultsInitialState, allSuccessful: true };

        let transactionsSuccessfulCount = transactions.length - transactionsErrored.length;

        const mapTransactionErrors = (transactionId, errors) =>
            errors.map((error) => `ID ${transactionId} - ${error.description}`);

        return {
            allSuccessful: false,
            successfulTotal: transactionsSuccessfulCount,
            errorTotal: transactionsErrored.length,
            errors: transactionsErrored.map((t) => mapTransactionErrors(t.id, t.errors)).flat(),
        };
    };

    const allFiltersAreSelected = () => {
        return (
            statusFilter.filter((status) => status.id !== 'all' && status.checked).length ===
            statusFilter.length - 1
        );
    };

    const allFiltersAreDeselected = () => {
        return (
            statusFilter.filter((status) => status.id !== 'all' && !status.checked).length ===
            statusFilter.length - 1
        );
    };

    const handleStatusFilterChange = (statusId) => {
        if (statusId === 'all') {
            const allIsSelected = statusFilter.filter((status) => status.id === 'all')[0].checked;
            setStatusFilter(
                statusFilter.map((status) => ({ id: status.id, checked: !allIsSelected })),
            );
        } else {
            const otherStatus = statusFilter.filter((status) => status.id !== statusId);
            const selectedStatus = statusFilter.filter((status) => status.id === statusId)[0];
            setStatusFilter([...otherStatus, { id: statusId, checked: !selectedStatus?.checked }]);
        }
    };

    const renderCheckBox = (rowData) => {
        return (
            <div className='action-checkbox-container'>
                <Checkbox
                    id={rowData.id.toString()}
                    checked={isCheckboxSelected(rowData.id.toString())}
                    onChange={handleCheckboxChange}
                    disabled={!rowData.enableSAPSend}
                />
            </div>
        );
    };

    const handleCheckboxChange = (checkboxId, checked) => {
        if (checkboxId === 'ALL') {
            if (checked) {
                setTransactionsSelected([
                    ...props.transactions
                        .filter((t) => t.statusAux === 'RR')
                        .map((t) => `${t.id.toString()}`),
                    'ALL',
                ]);
            } else setTransactionsSelected([]);
        } else {
            const filtered = transactionsSelected.filter(
                (transactionId) => transactionId === checkboxId,
            );
            if (checked) {
                // if the checkbox was checked and the checkbox id is not on transactionsSelected, add to it
                if (filtered.length === 0)
                    setTransactionsSelected([...transactionsSelected, `${checkboxId}`]);
            } else {
                // if the checkbox was deselected and the checkbox id is on transactionsSelected, remove from it
                if (filtered.length === 1)
                    setTransactionsSelected([
                        ...transactionsSelected.filter(
                            (transactionId) => transactionId !== checkboxId,
                        ),
                    ]);
            }
        }
    };

    const isCheckboxSelected = (checkboxId) => {
        const filtered = transactionsSelected.filter(
            (transactionId) => transactionId === checkboxId,
        );
        return filtered.length === 1;
    };

    const handleSendDataToSAPButtonClick = () => {
        if (transactionsSelected.length > 0) {
            const transactionsSelectedWithoutAllElement = transactionsSelected.filter(function (
                item,
            ) {
                return item !== 'ALL';
            });
            if (transactionsSelectedWithoutAllElement.length > 0) {
                props.sendDataToSAP(transactionsSelectedWithoutAllElement);
                setTransactionsSelected([]);
            }
        }
    };

    const getTableColumns = () => {
        // here could be some logic to show certain columns depending on user role.
        return [
            {
                id: 'id', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('nexMonitor.table.headers.id')}</h5>,
                accessor: (data) => data.id, // Custom value accessors!
                Cell: (props) => <span className='text'>{props.value}</span>,
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
                width: 100,
            },
            {
                id: 'tokenAmount', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('nexMonitor.table.headers.tokenAmount')}</h5>,
                accessor: (data) => parseFloat(data.tokenAmount), // Custom value accessors!
                Cell: (props) => <span className='text'>{props.value}</span>,
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'origin', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('nexMonitor.table.headers.origin')}</h5>,
                accessor: (data) => data.origin, // Custom value accessors!
                Cell: (props) => (
                    <span className='text'>{props.value === 'U' ? '' : props.value}</span>
                ),
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'destination', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('nexMonitor.table.headers.destination')}</h5>,
                accessor: (data) => data.destination, // Custom value accessors!
                Cell: (props) => <span className='text'>{props.value}</span>,
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'timeReceived', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('nexMonitor.table.headers.timeReceived')}</h5>,
                accessor: (data) => new Date(data.timeReceived), // Custom value accessors!
                Cell: (props) => <span className='text'>{parseDate(props.value)}</span>,
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'status', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('nexMonitor.table.headers.status')}</h5>,
                accessor: (data) => data.status, // Custom value accessors!
                Cell: (props) => <span className='text'>{props.value}</span>,
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'sendDataToSAP', // Required because our accessor is not a string
                Header: () => (
                    <div className='header-with-checkbox'>
                        <h5>{I18n.t('nexMonitor.table.headers.sendDataToSAP')}</h5>
                        <Checkbox
                            id='ALL'
                            checked={isCheckboxSelected('ALL')}
                            onChange={handleCheckboxChange}
                        />
                    </div>
                ),
                accessor: (data) => data.buttons, // Custom value accessors!
                Cell: (props) => renderCheckBox(props.original, props),
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
                sortable: false,
            },
        ].filter((column) => (props.userIsPayer ? column : column.id !== 'sendDataToSAP'));
    };

    return (
        <div className='nex-monitor-screen'>
            <div className='header'>
                <span className='title'>{I18n.t('nexMonitor.title')}</span>
                <SearchBar
                    textToSearch={textToSearch}
                    onSearch={(text) => {
                        setTextToSearch(text);
                        props.searchTransactions(text);
                    }}
                />
                <DateRangePicker
                    allTransactions={props.allTransactions}
                    textToSearch={textToSearch}
                    searchText={(text) => {
                        props.searchTransactions(text);
                    }}
                    updateFilteredTransactionsByDateRange={(newResults) => {
                        props.updateFilteredTransactionsByDateRange(newResults);
                    }}
                    clearDateRangeSelection={() => props.clearDateRangeSelection(textToSearch)}
                />
                <SelectMultiple
                    values={statusFilter}
                    onChange={handleStatusFilterChange}
                    className='rounded'
                    placeholder={I18n.t('nexMonitor.table.headers.status')}
                    options={[
                        { value: 'AE', label: I18n.t('nexMonitor.table.status.AE') },
                        { value: 'CR', label: I18n.t('nexMonitor.table.status.CR') },
                        { value: 'ER', label: I18n.t('nexMonitor.table.status.ER') },
                        { value: 'ES', label: I18n.t('nexMonitor.table.status.ES') },
                        { value: 'FI', label: I18n.t('nexMonitor.table.status.FI') },
                        { value: 'RR', label: I18n.t('nexMonitor.table.status.RR') },
                        { value: 'all', label: I18n.t('nexMonitor.table.status.all') },
                    ]}
                />
                <Button
                    type='button'
                    className='primary small download-button'
                    onClick={props.downloadTransactions}
                    loading={props.downloadStatus.loading}
                    text={I18n.t('nexMonitor.download')}
                    icon={<i className='icon-cloud-download' />}
                />
                {props.userIsPayer && (
                    <>
                        <Button
                            type='button'
                            className='primary small'
                            onClick={() => {
                                if (transactionsSelected.length > 0)
                                    setShowConfirmSendDataToSAPModal(true);
                            }}
                            text={I18n.t('nexMonitor.sendDataToSAP')}
                            dataTip={I18n.t('nexMonitor.sendDataToSAPDisabledTooltip')}
                            dataFor='sendDataToSAPDisabledTooltip'
                        />
                    </>
                )}
            </div>
            <div className='nex-monitor-table'>
                {props.loading ? (
                    <>
                        <div className='header' />
                        <div className='nex-monitor-table'>
                            <Loader />
                        </div>
                    </>
                ) : (
                    <ReactTable
                        className={
                            props.transactions.length > 0 ? 'nexus-table' : 'nexus-table empty'
                        }
                        PaginationComponent={(paginationProps) => (
                            <TablePagination
                                {...paginationProps}
                                total={props.total}
                                totalPages={props.totalPages}
                                pagedMessageTranslation='nexMonitor.table.pagedMessage'
                                pagedMessageEmptyTranslation='nexMonitor.table.pagedMessageEmpty'
                            />
                        )}
                        minRows={0}
                        pageSize={10}
                        data={props.transactions}
                        noDataText={I18n.t('nexMonitor.table.noRows')}
                        onSortedChange={() => {
                            // props.updateFilterValues({
                            //     ordering: (newSorted[0].desc ? "-" : "") + column.id
                            // });
                            // props.getTransactions();
                        }}
                        // todo check if this prop is necessary
                        getTdProps={() => {
                            return {
                                onClick: (e, handleOriginal) => {
                                    if (handleOriginal) {
                                        handleOriginal();
                                    }
                                },
                            };
                        }}
                        columns={getTableColumns()}
                        ref={(r) => {
                            setReactTable(r);
                        }}
                    />
                )}
            </div>
            <ConfirmationModal
                show={showConfirmSendDataToSAPModal}
                icon={<i className='icon-exclamation red' />}
                title={I18n.t('nexMonitor.confirmSentToSAPModal.text')}
                noBody={true}
                cancel={() => setShowConfirmSendDataToSAPModal(false)}
                submit={() => {
                    setShowConfirmSendDataToSAPModal(false);
                    handleSendDataToSAPButtonClick();
                }}
            />
            <TransactionsSuccessModal
                show={sendDataToSAPResults.allSuccessful && !props.sendDataToSAPStatus.error}
                onClick={() => {
                    setSendDataToSAPResults(sendDataToSAPResultsInitialState);
                    props.getTransactions();
                    // todo check how to clear the ui now, this might not work
                    props.clearUI();
                }}
                text={I18n.t('nexMonitor.successfulModal.transactionsSentToSAP')}
                status={props.sendDataToSAPStatus}
            />
            <TransactionsErrorModal
                show={
                    (sendDataToSAPResults.errorTotal > 0 || props.sendDataToSAPStatus.error) &&
                    !props.sendDataToSAPStatus.errorMessage
                }
                errors={sendDataToSAPResults.errors}
                isAValidationError={false}
                isASendDataToSAPError={true}
                close={() => {
                    setSendDataToSAPResults(sendDataToSAPResultsInitialState);
                    props.getTransactions();
                    // todo check how to clear the ui now, this might not work
                    props.clearUI();
                }}
                successfulTotal={sendDataToSAPResults.successfulTotal}
            />
        </div>
    );
};

export default NexMonitorScreen;
